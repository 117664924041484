import { startErrorLog } from './utils/handlingErrors';
import 'dayjs/locale/es';
import './i18n';
import RoutesIndex from './routes';
import { ToastContainer } from 'react-toastify';
import './react-toastify.css';
import { patchGoogleTranslateError } from './utils/patchGoogleTranslateError';

patchGoogleTranslateError();
startErrorLog();

function App() {
  return (
    <div>
      <RoutesIndex />
      <ToastContainer
        position="bottom-left"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable={false}
        pauseOnHover={false}
      />
    </div>
  );
}

export default App;
