/**
 * This function patches the Google Translate error when the user is using
 * the browser's translate feature.
 *
 * This error is caused by the Google Translate script trying to remove
 * or insert nodes that are not in the same document.
 *
 * @see https://github.com/facebook/react/issues/11538#issuecomment-417504600
 */
export function patchGoogleTranslateError() {
  if (typeof Node === 'function' && Node.prototype) {
    const originalRemoveChild = Node.prototype.removeChild;
    Node.prototype.removeChild = function <T extends Node>(child: T): T {
      if (child.parentNode !== this) {
        if (console) {
          console.error(
            'Cannot remove a child from a different parent',
            child,
            this,
          );
        }
        return child as T;
      }
      return originalRemoveChild.apply(this, [child]) as T;
    };

    const originalInsertBefore = Node.prototype.insertBefore;
    Node.prototype.insertBefore = function <T extends Node>(
      newNode: T,
      referenceNode: Node | null,
    ): T {
      if (referenceNode && referenceNode.parentNode !== this) {
        if (console) {
          console.error(
            'Cannot insert before a reference node from a different parent',
            referenceNode,
            this,
          );
        }
        return newNode as T;
      }
      return originalInsertBefore.apply(this, [newNode, referenceNode]) as T;
    };
  }
}
